:root {
  margin: 0;
  padding:0;
  width:100%,
}

body{
  margin: 0;
  padding: 0;
}

.gradient-bg {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), conic-gradient(from 86.31deg at 37.22% 103.11%, #5E1181 0deg, #9F0CE3 201.84deg, #2E2DAD 329.91deg, #3870FF 360deg);
}
.linear-gradient-bg {
  background: linear-gradient(90deg, rgba(159,12,227,1) 0%, rgba(94,17,129,1) 34%, rgba(46,45,173,1) 100%);
}

.rotate-anim {
  animation: rotating 1.3s linear infinite;
}

.capitalized-first {
  display:inline-block
}

.capitalized-first:first-letter {
  text-transform: uppercase;
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.d-flex-row-center-centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.d-flex-row-start-between{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.d-flex-row-center-between{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
}
.d-flex-col-center-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.d-flex-col-center-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items:center;
}
.d-flex-row-center-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.d-flex-col-center-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.d-flex-row-start-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.d-flex-row-start-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
.d-flex-row-start-centered {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.d-flex-row-end-centered {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.d-flex-row-around-centered {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.d-flex-col-start-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.d-flex-col-start-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}
.d-flex-row-between-centered {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.d-flex-row-between-start {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}


/* Styles for vectors */
.st0{clipPath:url(#SVGID_00000092454852054304013480000018329535964148865420_);}
.st1{fill:#9BA0D1;}
.st2{fill:#59267B;}
.st3{fill:#F2F9FB;}
.st4{fill:#F8CA7A;}
.st5{fill:#7682BF;}
.st6{fill:#FFFFFF;}
.st7{fill:#343433;}
.st8{fill:#585857;}
.st9{fill:#804997;}
.st10{fill:#F59E19;}
.st11{fill:#232C58;}
.st12{fill:#9E9D9C;}
.st13{fill:#EEEDED;}
.st14{fill:none;stroke:#7682BF;strokeMiterlimit:10;}
.st15{fill:#3D3D3B;}
.st16{fill:#E9807A;}
.st17{fill:#F6AEA4;}
.st18{fill:#DADADA;}
.st19{fill:#F8C0B6;}
.st20{fill:#E9998D;}
.st21{fill:#F59A32;}
.st22{fill:#F8B260;}
.st23{fill:#E68924;}
.st24{fill:url(#SVGID_00000111180717509842168580000015837409780610828428_);}
.st25{clipPath:url(#SVGID_00000092454852054304013480000018329535964148865420_);fill:#9BA0D1;}
.st26{fill-rule:evenodd;clip-rule:evenodd;fill:#59267B;}
.stopc1{stop-color:#ED7B35}
.stopc2{stop-color:#F49B59}

.logo-st0{fill:none;stroke:#FFFFFF;strokeMiterlimit:10;}
.logo-st1{fill:none;stroke:#4E02F7;strokeMiterlimit:10;}
.logo-cls-1 {
  stroke: #fff;
}

.logo-cls-1 , .logo-cls-1 {
  fill: none;
  strokeMiterlimit: 10;
}

.logo-cls-1 {
  stroke: #4e02f7;
}
.ups-st0 {
  fill:#341b14
}
.ups-st1 {
  fill:#ffb406
}